var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"product-program"},[_c('div',{staticClass:"product-program-content"},[_c('div',{staticClass:"product-program-title"},[_vm._v("产品方案")]),_c('div',{staticClass:"product-program-module"},[_c('div',{staticClass:"product-program-module-left"},[_c('div',{staticClass:"product-program-module-left-box"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSwitchIndex === 0),expression:"activeSwitchIndex === 0"}],class:{
              'phone-scale-hide': true,
              'phone-scale': _vm.isShowRight,
            },attrs:{"src":require("@/assets/images/solution/product-program-phone-yszc.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSwitchIndex === 1),expression:"activeSwitchIndex === 1"}],class:{
              'phone-scale-hide': true,
              'phone-scale': _vm.isShowRight,
            },attrs:{"src":require("@/assets/images/solution/product-program-phone-dzjqr.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSwitchIndex === 2),expression:"activeSwitchIndex === 2"}],class:{
              'phone-scale-hide': true,
              'phone-scale': _vm.isShowRight,
            },attrs:{"src":require("@/assets/images/solution/product-program-phone-ykp.png"),"alt":""}})])]),_c('div',{staticClass:"product-program-module-right"},[_c('div',{staticClass:"product-program-module-right-switch"},_vm._l((_vm.switchItems),function(switchItem,switchIndex){return _c('div',{key:switchItem.key,staticClass:"product-program-module-right-switch-item",class:{
              'product-program-module-right-switch-item-active':
                _vm.activeSwitchIndex === switchIndex,
            },on:{"mouseover":() => {
                _vm.clickSwitchItem(switchIndex)
              }}},[_c('img',{attrs:{"src":switchItem.img,"alt":""}})])}),0),_vm._l((_vm.switchItems),function(switchItem,switchIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSwitchIndex === switchIndex),expression:"activeSwitchIndex === switchIndex"}],key:switchItem.key,staticClass:"product-program-module-right-content"},[_c('div',{staticClass:"product-program-module-right-content-title",class:{
              'right-hide': true,
              'move-right-title': _vm.isShowRight,
            }},[_vm._v(" "+_vm._s(switchItem.title)+" ")]),_c('div',{staticClass:"product-program-module-right-content-label",class:{
              'right-hide': true,
              'move-right-label': _vm.isShowRight,
            }},[_vm._v(" "+_vm._s(switchItem.label)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSwitchIndex === 0),expression:"activeSwitchIndex === 0"}],staticClass:"product-program-module-right-content-yszc",class:{
              'right-hide': true,
              'move-right-content': _vm.isShowRight,
            }},_vm._l((_vm.productProgramYszcItems),function(productProgramYszcItem){return _c('div',{key:productProgramYszcItem.label,staticClass:"product-program-module-right-content-yszc-item"},[_c('img',{attrs:{"src":productProgramYszcItem.img,"alt":""}}),_c('div',{staticClass:"product-program-module-right-content-yszc-item-label"},[_vm._v(" "+_vm._s(productProgramYszcItem.label)+" ")])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSwitchIndex === 1),expression:"activeSwitchIndex === 1"}],staticClass:"product-program-module-right-content-dzjqr",class:{
              'right-hide': true,
              'move-right-content': _vm.isShowRight,
            }},_vm._l((_vm.productProgramDzjqrItems),function(productProgramDzjqrItem){return _c('div',{key:productProgramDzjqrItem.label,staticClass:"product-program-module-right-content-dzjqr-item"},[_c('div',{staticClass:"product-program-module-right-content-dzjqr-item-top"},[_c('div',{staticClass:"product-program-module-right-content-dzjqr-item-top-title"},[_vm._v(" "+_vm._s(productProgramDzjqrItem.title)+" ")]),_vm._l((productProgramDzjqrItem.labelList),function(labelItem){return _c('p',{key:labelItem},[_vm._v(" "+_vm._s(labelItem)+" ")])})],2),_vm._m(0,true),_c('div',{staticClass:"product-program-module-right-content-dzjqr-item-bottom"},[_vm._v(" "+_vm._s(productProgramDzjqrItem.btnText)+" ")])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSwitchIndex === 2),expression:"activeSwitchIndex === 2"}],staticClass:"product-program-module-right-content-ykp",class:{
              'right-hide': true,
              'move-right-content': _vm.isShowRight,
            }},_vm._l((_vm.productProgramYkpItems),function(productProgramYkpItem){return _c('div',{key:productProgramYkpItem.label,staticClass:"product-program-module-right-content-ykp-item"},[_c('img',{attrs:{"src":productProgramYkpItem.img,"alt":""}}),_c('div',{staticClass:"product-program-module-right-content-ykp-item-label"},[_vm._v(" "+_vm._s(productProgramYkpItem.label)+" ")])])}),0)])})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-program-module-right-content-dzjqr-item-middle"},[_c('img',{attrs:{"src":require("@/assets/images/solution/product-program-dzjqr-bottom.png"),"alt":""}})])
}]

export { render, staticRenderFns }