<template>
  <div class="solution-customer">
    <Navgation bgColor="#060B4C" />
    <div class="solution-customer-container">
      <section class="banner">
        <div class="banner-content">
          <div class="banner-content-title">客户解决方案</div>
          <div class="banner-content-label">
            手机端自助完成工商注册、记账报税全流程操作 快速拿证，一键报税
          </div>
        </div>
      </section>
      <section class="industry-problem">
        <div
          class="industry-problem-content"
          :class="{
            'pre-hide-300': true,
            'move-bottom-300': isShowIndustryProblem,
          }"
        >
          <div class="industry-problem-title section-title">行业痛点</div>
          <div class="industry-problem-modules">
            <div
              class="industry-problem-modules-item"
              :class="{
                'right-hide': true,
                'move-right':
                  activeIndustryProblemItem === industryProblemIndex,
              }"
              v-for="(
                industryProblemItem, industryProblemIndex
              ) in industryProblemList"
              :key="industryProblemItem.title"
              v-show="activeIndustryProblemItem === industryProblemIndex"
            >
              <div class="industry-problem-modules-item-left">
                <img :src="industryProblemItem.img" alt="" />
              </div>
              <div class="industry-problem-modules-item-right">
                <div class="industry-problem-modules-item-right-title">
                  {{ industryProblemItem.title }}
                </div>
                <div class="industry-problem-modules-item-right-line"></div>
                <div class="industry-problem-modules-item-right-problems">
                  <div
                    class="industry-problem-modules-item-right-problems-item"
                    v-for="problemItem in industryProblemItem.problems"
                    :key="problemItem"
                  >
                    {{ problemItem }}
                  </div>
                </div>
              </div>
            </div>
            <div class="industry-problem-modules-switch">
              <div
                class="industry-problem-modules-switch-btn"
                :class="{
                  'industry-problem-modules-switch-btn-active':
                    activeIndustryProblemItem === switchIndex,
                }"
                v-for="(switchItem, switchIndex) in industryProblemList"
                :key="switchItem.title"
                @click="switchClick(switchIndex)"
              ></div>
            </div>
          </div>
        </div>
      </section>
      <!-- 产品方案 -->
      <section style="background: #060b4c">
        <ProductProgram
          :class="{
            'pre-hide-100': true,
            'move-bottom-100-1': isShowProductProgram,
          }"
        />
      </section>

      <!-- 使用方式 -->
      <Cooperation type="customer" :height="668" />
    </div>
  </div>
</template>
<script>
import Navgation from '@/layouts/components/Navgation'
import ProductProgram from '../components/productProgram.vue'
import Cooperation from '../components/cooperation.vue'

export default {
  name: 'solutionCustomer',
  components: { Navgation, ProductProgram, Cooperation },
  inject: ['getPosition', 'scrollTo'],
  data() {
    return {
      industryProblemList: [
        {
          title: '工商注册痛点',
          img: require('@/assets/images/solution/customer-industry-problem-icon.png'),
          problems: [
            '① 代办鱼龙混杂，有隐性收费',
            '② 办理进度不透明，拿证遥遥无期',
            '③ 没工商注册经验，不懂流程，难以自助办理',
          ],
        },
        {
          title: '代理记账痛点',
          img: require('@/assets/images/solution/customer-industry-problem-icon2.png'),
          problems: [
            '① 每月发票不对，代账费两三千，太贵了',
            '② 对代账不放心，想自己做，更安心',
            '③ 公司没会计，又想自己记账报税',
          ],
        },
      ],
      activeIndustryProblemItem: 0,
      isShowIndustryProblem: false,
      isShowProductProgram: false,
      switchTimer: null,
    }
  },
  mounted() {
    this.isShowIndustryProblem = true
    this.switchTimer = setInterval(() => {
      if (
        this.activeIndustryProblemItem <
        this.industryProblemList.length - 1
      ) {
        this.activeIndustryProblemItem += 1
      } else {
        this.activeIndustryProblemItem = 0
      }
    }, 2000)
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 400) {
          this.isShowProductProgram = true
        }
      } else {
        if (scrollTop >= 650) {
          this.isShowProductProgram = true
        }
      }
    },
    switchClick(switchIndex) {
      this.activeIndustryProblemItem = switchIndex
      clearInterval(this.switchTimer)
      this.switchTimer = setInterval(() => {
        if (
          this.activeIndustryProblemItem <
          this.industryProblemList.length - 1
        ) {
          this.activeIndustryProblemItem += 1
        } else {
          this.activeIndustryProblemItem = 0
        }
      }, 2000)
    },
  },
}
</script>
<style lang="less" scoped>
.solution-customer-container {
  margin-top: @navHeight;
  .section-title {
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #102a55;
  }
  .banner {
    height: 440px;
    background: url('../../../assets/images/solution/customer-banner-bg.png')
      no-repeat;
    background-size: 100% 440px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        margin-top: 144px;
        font-size: 54px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 75px;
        background: linear-gradient(
          270deg,
          #51ffc4 0%,
          #24eaff 40%,
          #1464ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &-label {
        margin-top: 12px;
        width: 420px;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }
  .industry-problem {
    height: 650px;
    overflow: hidden;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .industry-problem-title {
        margin-top: 90px;
      }
      .industry-problem-modules {
        margin-top: 80px;
        &-item {
          width: 1024px;
          height: 304px;
          background: #ffffff;
          box-shadow: 0px 0px 40px 0px rgba(171, 190, 214, 0.38);
          display: flex;
          &-left {
            img {
              width: 378px;
              height: 304px;
            }
          }
          &-right {
            margin-left: 90px;
            &-title {
              margin-top: 46px;
              font-size: 32px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #060b4c;
            }
            &-line {
              margin-top: 18px;
              width: 32px;
              height: 4px;
              background: #2871ff;
              border-radius: 2px;
            }
            &-problems {
              margin-top: 28px;
              &-item {
                margin-top: 8px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(16, 42, 85, 0.65);
                line-height: 25px;
              }
            }
          }
        }
        &-switch {
          margin-top: 26px;
          display: flex;
          justify-content: center;
          &-btn {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #d0d5dd;
            opacity: 0.5;
            margin-right: 8px;
            cursor: pointer;
          }
          &-btn-active {
            width: 16px;
            height: 8px;
            background: #d0d5dd;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .pre-hide-300 {
    transform: translateY(300px);
    opacity: 0;
  }
  .right-hide {
    transform: translateX(100px);
    opacity: 0;
  }
  .move-right {
    animation: move-right 1s 0s forwards ease-in-out;
  }
  .move-bottom-300 {
    animation: move-bottom-300 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-300-1 {
    animation: move-bottom-300-1 1s 0.2s forwards ease-in-out;
  }
  .pre-hide-100 {
    transform: translateY(100px);
    opacity: 0;
  }
  .move-bottom-100 {
    animation: move-bottom-100 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-100-1 {
    animation: move-bottom-100-1 1s 0.2s forwards ease-in-out;
  }
  @keyframes move-right {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-300 {
    0% {
      transform: translateY(300px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-300-1 {
    0% {
      transform: translateY(300px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-100 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-100-1 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
</style>
