var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-customer"},[_c('Navgation',{attrs:{"bgColor":"#060B4C"}}),_c('div',{staticClass:"solution-customer-container"},[_vm._m(0),_c('section',{staticClass:"industry-problem"},[_c('div',{staticClass:"industry-problem-content",class:{
          'pre-hide-300': true,
          'move-bottom-300': _vm.isShowIndustryProblem,
        }},[_c('div',{staticClass:"industry-problem-title section-title"},[_vm._v("行业痛点")]),_c('div',{staticClass:"industry-problem-modules"},[_vm._l((_vm.industryProblemList),function(industryProblemItem,industryProblemIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndustryProblemItem === industryProblemIndex),expression:"activeIndustryProblemItem === industryProblemIndex"}],key:industryProblemItem.title,staticClass:"industry-problem-modules-item",class:{
              'right-hide': true,
              'move-right':
                _vm.activeIndustryProblemItem === industryProblemIndex,
            }},[_c('div',{staticClass:"industry-problem-modules-item-left"},[_c('img',{attrs:{"src":industryProblemItem.img,"alt":""}})]),_c('div',{staticClass:"industry-problem-modules-item-right"},[_c('div',{staticClass:"industry-problem-modules-item-right-title"},[_vm._v(" "+_vm._s(industryProblemItem.title)+" ")]),_c('div',{staticClass:"industry-problem-modules-item-right-line"}),_c('div',{staticClass:"industry-problem-modules-item-right-problems"},_vm._l((industryProblemItem.problems),function(problemItem){return _c('div',{key:problemItem,staticClass:"industry-problem-modules-item-right-problems-item"},[_vm._v(" "+_vm._s(problemItem)+" ")])}),0)])])}),_c('div',{staticClass:"industry-problem-modules-switch"},_vm._l((_vm.industryProblemList),function(switchItem,switchIndex){return _c('div',{key:switchItem.title,staticClass:"industry-problem-modules-switch-btn",class:{
                'industry-problem-modules-switch-btn-active':
                  _vm.activeIndustryProblemItem === switchIndex,
              },on:{"click":function($event){return _vm.switchClick(switchIndex)}}})}),0)],2)])]),_c('section',{staticStyle:{"background":"#060b4c"}},[_c('ProductProgram',{class:{
          'pre-hide-100': true,
          'move-bottom-100-1': _vm.isShowProductProgram,
        }})],1),_c('Cooperation',{attrs:{"type":"customer","height":668}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner"},[_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-content-title"},[_vm._v("客户解决方案")]),_c('div',{staticClass:"banner-content-label"},[_vm._v(" 手机端自助完成工商注册、记账报税全流程操作 快速拿证，一键报税 ")])])])
}]

export { render, staticRenderFns }