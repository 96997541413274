<template>
  <section class="cooperation-way" :style="{ height: height + 'px' }">
    <img
      class="cooperation-way-bg2"
      src="@/assets/images/solution/cooperation-way-bg2.png"
      alt=""
    />
    <img
      class="cooperation-way-bg1"
      src="@/assets/images/solution/cooperation-way-bg1.png"
      alt=""
    />
    <div
      class="cooperation-way-content"
      :class="{
        'pre-hide-100': true,
        'move-bottom-100': isShowCooperation,
      }"
    >
      <div class="cooperation-way-title">{{ titleObj[type] }}</div>
      <div class="cooperation-way-label">{{ labelObj[type] }}</div>
      <div class="cooperation-way-qrBox" v-if="type === 'customer'">
        <div class="cooperation-way-qr">
          <img
            class="qr-img"
            src="@/assets/images/product/yszc-qr.png"
            alt=""
          />
          <div class="qr-label">云上注册</div>
        </div>
        <div class="cooperation-way-qr">
          <img
            class="qr-img"
            src="@/assets/images/product/dzjqr-qr.png"
            alt=""
          />
          <div class="qr-label">代账机器人</div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'cooperationWay',
  props: {
    type: {
      required: true, // bank || customer || partner
    },
    height: {
      default: 540,
    },
  },
  inject: ['getPosition', 'scrollTo'],
  data() {
    return {
      titleObj: {
        bank: '合作方式',
        customer: '使用方式',
        partner: '合作方式',
      },
      labelObj: {
        bank: '十角兽微信公众号、小程序、企业手机银行App功能嫁接，助力银行打造中小企业数字化营销运营平台，为中小企业提供一站式工商注册、掌上自助记账报税服务，从而实现数字化场景获客、黏客，提升客户体验和客户价值',
        customer:
          '扫描二维码即可体验“十角兽云上注册”和“十角兽代账机器人”，轻松自助完成工商注册、记账报税，享受便利的互联网工商财税服务体验',
        partner:
          '0门槛即可成为十角兽合伙人，签约之日起享受总部全方位的产品与技术服务支持，帮助您解决企业服务产品同质化严重，缺乏竞争力的痛点，有效地帮助您拓宽获客渠道，降低获客成本，提高用户黏性和复购率，祝您打造数字化工商财税+传统企业服务全新生态',
      },
      isShowCooperation: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 1400) {
          this.isShowCooperation = true
        }
      } else {
        if (scrollTop >= 1650) {
          this.isShowCooperation = true
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.cooperation-way {
  // height: 540px;
  background: #2871ff;
  position: relative;
  &-content {
    width: @panelWidth;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .cooperation-way-title {
      // position: absolute;
      // top: 180px;
      margin-top: 180px;
      font-size: 65px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 91px;
    }
    .cooperation-way-label {
      margin-top: 34px;
      width: 1024px;
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 28px;
    }
    .cooperation-way-qrBox {
      margin-top: 118px;
      display: flex;
      .cooperation-way-qr {
        display: flex;
        flex-direction: column;
        align-items: center;
        .qr-img {
          width: 130px;
          height: 130px;
        }
        .qr-label {
          margin-top: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
      }
      .cooperation-way-qr + .cooperation-way-qr {
        margin-left: 242px;
      }
    }
  }
  &-bg1 {
    width: 244px;
    height: 250px;
    // margin-top: 70px;
    position: absolute;
    top: 70px;
    left: calc(50% - 122px);
  }
  &-bg2 {
    width: 188px;
    height: 134px;
    position: absolute;
    bottom: -45px;
    left: -24px;
    z-index: 3;
  }
  .pre-hide-100 {
    transform: translateY(100px);
    opacity: 0;
  }
  .move-bottom-100 {
    animation: move-bottom-100 1s 0.2s forwards ease-in-out;
  }
  @keyframes move-bottom-100 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
</style>
