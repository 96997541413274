<template>
  <section class="product-program">
    <div class="product-program-content">
      <div class="product-program-title">产品方案</div>
      <div class="product-program-module">
        <div class="product-program-module-left">
          <div class="product-program-module-left-box">
            <img
              :class="{
                'phone-scale-hide': true,
                'phone-scale': isShowRight,
              }"
              v-show="activeSwitchIndex === 0"
              src="@/assets/images/solution/product-program-phone-yszc.png"
              alt=""
            />
            <img
              :class="{
                'phone-scale-hide': true,
                'phone-scale': isShowRight,
              }"
              v-show="activeSwitchIndex === 1"
              src="@/assets/images/solution/product-program-phone-dzjqr.png"
              alt=""
            />
            <img
              :class="{
                'phone-scale-hide': true,
                'phone-scale': isShowRight,
              }"
              v-show="activeSwitchIndex === 2"
              src="@/assets/images/solution/product-program-phone-ykp.png"
              alt=""
            />
          </div>
        </div>
        <div class="product-program-module-right">
          <div class="product-program-module-right-switch">
            <div
              class="product-program-module-right-switch-item"
              :class="{
                'product-program-module-right-switch-item-active':
                  activeSwitchIndex === switchIndex,
              }"
              v-for="(switchItem, switchIndex) in switchItems"
              :key="switchItem.key"
              @mouseover="
                () => {
                  clickSwitchItem(switchIndex)
                }
              "
            >
              <img :src="switchItem.img" alt="" />
            </div>
          </div>
          <div
            class="product-program-module-right-content"
            v-for="(switchItem, switchIndex) in switchItems"
            :key="switchItem.key"
            v-show="activeSwitchIndex === switchIndex"
          >
            <div
              class="product-program-module-right-content-title"
              :class="{
                'right-hide': true,
                'move-right-title': isShowRight,
              }"
            >
              {{ switchItem.title }}
            </div>
            <div
              class="product-program-module-right-content-label"
              :class="{
                'right-hide': true,
                'move-right-label': isShowRight,
              }"
            >
              {{ switchItem.label }}
            </div>
            <div
              class="product-program-module-right-content-yszc"
              v-show="activeSwitchIndex === 0"
              :class="{
                'right-hide': true,
                'move-right-content': isShowRight,
              }"
            >
              <div
                class="product-program-module-right-content-yszc-item"
                v-for="productProgramYszcItem in productProgramYszcItems"
                :key="productProgramYszcItem.label"
              >
                <img :src="productProgramYszcItem.img" alt="" />
                <div
                  class="product-program-module-right-content-yszc-item-label"
                >
                  {{ productProgramYszcItem.label }}
                </div>
              </div>
            </div>
            <div
              class="product-program-module-right-content-dzjqr"
              v-show="activeSwitchIndex === 1"
              :class="{
                'right-hide': true,
                'move-right-content': isShowRight,
              }"
            >
              <div
                class="product-program-module-right-content-dzjqr-item"
                v-for="productProgramDzjqrItem in productProgramDzjqrItems"
                :key="productProgramDzjqrItem.label"
              >
                <div
                  class="product-program-module-right-content-dzjqr-item-top"
                >
                  <div
                    class="product-program-module-right-content-dzjqr-item-top-title"
                  >
                    {{ productProgramDzjqrItem.title }}
                  </div>
                  <p
                    v-for="labelItem in productProgramDzjqrItem.labelList"
                    :key="labelItem"
                  >
                    {{ labelItem }}
                  </p>
                </div>
                <div
                  class="product-program-module-right-content-dzjqr-item-middle"
                >
                  <img
                    src="@/assets/images/solution/product-program-dzjqr-bottom.png"
                    alt=""
                  />
                </div>
                <div
                  class="product-program-module-right-content-dzjqr-item-bottom"
                >
                  {{ productProgramDzjqrItem.btnText }}
                </div>
              </div>
            </div>
            <div
              class="product-program-module-right-content-ykp"
              v-show="activeSwitchIndex === 2"
              :class="{
                'right-hide': true,
                'move-right-content': isShowRight,
              }"
            >
              <div
                class="product-program-module-right-content-ykp-item"
                v-for="productProgramYkpItem in productProgramYkpItems"
                :key="productProgramYkpItem.label"
              >
                <img :src="productProgramYkpItem.img" alt="" />
                <div
                  class="product-program-module-right-content-ykp-item-label"
                >
                  {{ productProgramYkpItem.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'productProgram',
  data() {
    return {
      switchItems: [
        {
          img: require('@/assets/images/solution/product-program-switch-yszc.png'),
          key: 'yszc',
          title: '云上注册',
          label:
            '足不出户，免费办理。用户掌上即可自助完成工商注册全流程，最快1天即可拿证',
        },
        {
          img: require('@/assets/images/solution/product-program-switch-dzjqr.png'),
          key: 'dzjqr',
          title: '代账机器人',
          label: '每月仅需核对票据、确认金额，一键报税，三步即可完成公司报税',
        },
        {
          img: require('@/assets/images/solution/product-program-switch-ykp.png'),
          key: 'ykp',
          title: '云开票',
          label:
            '将硬件小方盒连接网线与税务UKey,便可完成云端部署，实现在线开票，无需连接电脑',
        },
      ],
      activeSwitchIndex: 0,
      // activeSwitchIndexTimer: null,
      productProgramYszcItems: [
        {
          img: require('@/assets/images/solution/product-program-yszc-icon1.png'),
          label: '起名/测名/核名',
        },
        {
          img: require('@/assets/images/solution/product-program-yszc-icon2.png'),
          label: '经营范围',
        },
        {
          img: require('@/assets/images/solution/product-program-yszc-icon3.png'),
          label: '工商注册',
        },
        {
          img: require('@/assets/images/solution/product-program-yszc-icon4.png'),
          label: '银行开户',
        },
      ],
      productProgramDzjqrItems: [
        {
          title: '自动采集',
          labelList: [
            '自动采集税务发票',
            '自动获取银行流水',
            '自动汇总整理票据',
          ],
          btnText: '核对',
        },
        {
          title: '自动算税',
          labelList: [
            '单据自动识别',
            '凭证自动生成',
            '税表自动生成',
            '损益自动结转',
          ],
          btnText: '确认',
        },
        {
          title: '自动报税',
          labelList: ['税种鉴定管理', '税金自动计算', '一键确认申报'],
          btnText: '报税',
        },
      ],
      productProgramYkpItems: [
        {
          label: '开票设备托管',
          img: require('@/assets/images/solution/product-program-ykp-icon1.png'),
        },
        {
          label: '移动在线开票',
          img: require('@/assets/images/solution/product-program-ykp-icon2.png'),
        },
        {
          label: '自动抄税清卡',
          img: require('@/assets/images/solution/product-program-ykp-icon3.png'),
        },
      ],
      isShowRight: true,
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.activeSwitchIndexTimer = setInterval(() => {
    //     if (this.activeSwitchIndex >= 2) {
    //       this.activeSwitchIndex = 0
    //     } else {
    //       this.activeSwitchIndex++
    //     }
    //   }, 2000)
    // }, 2000)
  },
  methods: {
    clickSwitchItem(switchIndex) {
      if (this.activeSwitchIndex !== switchIndex) {
        this.activeSwitchIndex = switchIndex
        // setTimeout(() => {
        this.isShowRight = true
        // }, 500)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.product-program {
  height: 996px;
  background: #060b4c;
  &-content {
    width: @panelWidth;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .product-program-title {
      margin-top: 90px;
      font-size: 48px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 67px;
    }
    .product-program-module {
      width: 100%;
      margin-top: 36px;
      display: flex;
      justify-content: space-between;
      &-left {
        margin-right: 115px;
        margin-top: 164px;
        // position: relative;
        &-box {
          // position: absolute;
          // left: -4px;
          // top: -4px;
          // z-index: 2;
          overflow: hidden;
          width: 276px;
          height: 590px;
          border-radius: 32px;
          border: 10px solid #2d316e;
          img {
            position: relative;
            left: -3px;
            top: -3px;
            width: 282px;
            height: 596px;
            // width: 290px;
            // height: 604px;
          }
        }
      }
      &-right {
        width: 730px;
        &-switch {
          display: flex;
          &-item {
            margin-right: 56px;
            margin-top: 20px;
            img {
              width: 80px;
              height: 80px;
            }
          }
          &-item-active {
            margin-top: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 100px;
              height: 100px;
            }
            &::after {
              content: '';
              display: block;
              margin-top: 16px;
              width: 10px;
              height: 10px;
              background: #2871ff;
              border-radius: 50%;
            }
          }
        }
        &-content {
          &-title {
            margin-top: 66px;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
          }
          &-label {
            margin-top: 16px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.65);
            line-height: 22px;
          }
          &-yszc {
            display: flex;
            flex-wrap: wrap;
            margin-top: 58px;
            &-item {
              margin-bottom: 36px;
              margin-right: 36px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 328px;
              height: 160px;
              background: rgba(255, 255, 255, 0.04);
              border-radius: 16px;
              img {
                width: 56px;
                height: 56px;
                margin-bottom: 16px;
              }
              &-label {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 22px;
              }
            }
          }
          &-dzjqr {
            display: flex;
            margin-top: 58px;
            &-item {
              margin-right: 24px;
              display: flex;
              flex-direction: column;
              align-items: center;
              &-top {
                width: 214px;
                height: 240px;
                background: rgba(255, 255, 255, 0.04);
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                align-items: center;
                &-title {
                  font-size: 20px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                  line-height: 28px;
                  margin: 42px 0px 20px 0px;
                }
                p {
                  font-size: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(255, 255, 255, 0.5);
                  line-height: 22px;
                  margin-bottom: 6px;
                }
              }
              &-middle {
                margin: 8px 0px 6px 0px;
                img {
                  width: 68px;
                  height: 54px;
                }
              }
              &-bottom {
                width: 214px;
                height: 70px;
                text-align: center;
                background: rgba(255, 255, 255, 0.09);
                border-radius: 16px;
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2871ff;
                line-height: 70px;
              }
            }
          }
          &-ykp {
            margin-top: 68px;
            display: flex;
            &-item {
              width: 214px;
              height: 214px;
              background: rgba(255, 255, 255, 0.04);
              border-radius: 16px;
              margin-right: 24px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              img {
                width: 56px;
                height: 56px;
              }
              &-label {
                margin-top: 22px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
  .right-hide {
    transform: translateX(100px);
    opacity: 0;
  }
  .phone-scale-hide {
    transform: scale(1.1);
  }
  .phone-scale {
    animation: phone-scale 1s 0s forwards ease-in-out;
  }
  .move-right-title {
    animation: move-right 1s 0s forwards ease-in-out;
  }
  .move-right-label {
    animation: move-right-label 1s 0.25s forwards ease-in-out;
  }
  .move-right-content {
    animation: move-right-content 1s 0.5s forwards ease-in-out;
  }
  @keyframes move-right {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes move-right-label {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes move-right-content {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  @keyframes phone-scale {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
